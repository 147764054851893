export const restructureToTree = (sensors) => {
  const customers = []

  sensors.forEach((sensor) => {
    const s = { ...sensor }
    let cIndex = customers.findIndex((c) => c.id === s.customerId)
    let lIndex = -1
    let sIndex = -1
    let gIndex = -1
    let rIndex = -1

    // Register the customer for the first time
    if (cIndex === -1) {
      customers.push(mapNode(s.customer, 'customer'))
      cIndex = customers.findIndex((c) => c.id === s.customerId)
    }

    // Register location
    if (s.locationId) {
      lIndex = customers[cIndex].children.findIndex((l) => l.id === s.locationId)
      if (lIndex === -1) {
        customers[cIndex].children.push(mapNode(s.location, 'location'))
        lIndex = customers[cIndex].children.findIndex((l) => l.id === s.locationId)
      }
    }

    // Register site
    if (s.siteId && lIndex !== -1) {
      sIndex = customers[cIndex].children[lIndex].children.findIndex((site) => site.id === s.siteId)
      if (sIndex === -1) {
        customers[cIndex].children[lIndex].children.push(mapNode(s.site, 'site'))
        sIndex = customers[cIndex].children[lIndex].children.findIndex((site) => site.id === s.siteId)
      }
    }

    // Register group
    if (s.groupId && sIndex !== -1) {
      gIndex = customers[cIndex].children[lIndex].children[sIndex].children.findIndex((g) => g.id === s.groupId)
      if (sIndex === -1) {
        customers[cIndex].children[lIndex].children[sIndex].children.push(mapNode(s.group, 'group'))
        gIndex = customers[cIndex].children[lIndex].children[sIndex].children.findIndex((g) => g.id === s.groupId)
      }
    }

    // Register room
    if (s.room && gIndex !== -1) {
      rIndex = customers[cIndex].children[lIndex].children[sIndex].children[gIndex].children.findIndex((r) => r.id === s.roomId)
      if (rIndex === -1) {
        customers[cIndex].children[lIndex].children[sIndex].children[gIndex].children.push(mapNode(s.room, 'room'))
        rIndex = customers[cIndex].children[lIndex].children[sIndex].children[gIndex].children.findIndex((r) => r.id === s.roomId)
      }
    }

    // Insert the sensor in the right level
    if (rIndex !== -1) {
      customers[cIndex].children[lIndex].children[sIndex].children[gIndex].children[rIndex].sensors.push(mapNode(s, 'sensor'))
    } else if (gIndex !== -1) {
      customers[cIndex].children[lIndex].children[sIndex].children[gIndex].children.push(mapNode(s, 'sensor'))
    } else if (sIndex !== -1) {
      customers[cIndex].children[lIndex].children[sIndex].children.push(mapNode(s, 'sensor'))
    } else if (lIndex !== -1) {
      customers[cIndex].children[lIndex].children.push(mapNode(s, 'sensor'))
    } else {
      customers[cIndex].children.push(mapNode(s, 'sensor'))
    }
  })

  return customers
}

export const searchTree = (search, nodes) => {
  const searchedArrays = nodes.map((n) => {
    let results = []

    if (n.children) {
      results = results.concat(searchTree(search, n.children))
    }

    if (n.title.toLowerCase().indexOf(search.toLowerCase()) > -1) {
      results = results.concat([n.key])
    } else {
      results = results.concat([null])
    }

    return results
  })

  const mergedSearch = [].concat.apply([], searchedArrays)

  return mergedSearch.filter((n) => n)
}

export const mapNode = (item, type) => {
  return {
    id: item.id,
    title: item.name,
    key: `${type}-${item.id}`,
    dataItem: { ...item },
    children: []
  }
}
