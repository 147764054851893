import { AlarmRuleKey, createChartAnnotations } from '../shared/helpers'
import { Col, DatePicker, Descriptions, Row, Spin, Typography } from 'antd'
import React, { Fragment, useEffect, useState } from 'react'

import { Area } from '@ant-design/charts'
import axios from 'axios'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const CustomDashboard = (props) => {
  const [sensor, setSensor] = useState(null)
  const [rules, setRules] = useState([])
  const { t } = useTranslation(['global', 'dashboards'])
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(moment(new Date()))
  const [endDate, setEndDate] = useState(moment(new Date()))
  const [temperatureData, setTemperatureData] = useState([])
  const [batteryData, setBatteryData] = useState([])
  const [humidityData, setHumidityData] = useState([])
  const [co2Data, setCo2Data] = useState([])

  const fetchData = () => {
    if (!sensor) return

    setLoading(true)
    const start = startDate.format('DD-MM-YYYY')
    const end = endDate.format('DD-MM-YYYY')

    axios
      .get(`/api/data/${sensor.id}/custom/${start}/${end}`)
      .then((res) => {
        processData(res.data)
        props.onUpdated()
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false))
  }

  const processData = (data) => {
    const temp = []
    const bat = []
    const hum = []
    const co2 = []

    data.forEach((item) => {
      const dateString = moment(item.timestamp).format('ddd LT')

      temp.push({ date: dateString, value: item.temperature })
      bat.push({ date: dateString, value: item.battery })
      hum.push({ date: dateString, value: item.humidity })
      co2.push({ date: dateString, value: item.co2 })
    })

    setTemperatureData(temp)
    setBatteryData(bat)
    setHumidityData(hum)
    setCo2Data(co2)
  }

  const getChartAnnotations = (key) => {
    const kIndex = rules.findIndex((x) => x.key === key)
    if (kIndex !== -1) {
      const kRule = rules[kIndex]
      return createChartAnnotations(kRule.minValue, kRule.maxValue)
    }

    return []
  }

  const onDateChange = (dates) => {
    setStartDate(moment(dates[0]))
    setEndDate(moment(dates[1]))
  }

  useEffect(() => {
    setRules(props.rules)
  }, [props.rules])

  useEffect(() => {
    setSensor(props.sensor)
  }, [props.sensor])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensor, startDate, endDate, props.autoUpdate])

  return (
    <>
      <Spin spinning={loading}>
        {sensor && (
          <div>
            <Typography.Title level={2}>{t('dashboards:custom_dashboard')}</Typography.Title>
            <Descriptions column={6} labelStyle={{ color: 'rgb(195 195 195)' }}>
              <Descriptions.Item label={t('dashboards:sensor')}>{sensor.name}</Descriptions.Item>
              <Descriptions.Item label={t('dashboards:location')}>{sensor.location?.name}</Descriptions.Item>
              <Descriptions.Item label={t('dashboards:site')}>{sensor.site?.name}</Descriptions.Item>
              <Descriptions.Item label={t('dashboards:room')}>{sensor.room?.name}</Descriptions.Item>
              <Descriptions.Item label={t('dashboards:date')}>
                {/* <DatePicker size="small" onChange={onDateChange} defaultValue={moment(startDate)} /> */}
                <DatePicker.RangePicker defaultValue={[startDate, endDate]} onChange={onDateChange} size="small" />
              </Descriptions.Item>
            </Descriptions>

            <Row>
              {props.charts &&
                props.charts.map((chart) => (
                  <Fragment key={chart}>
                    {chart === 'dashboards:temperature' && (
                      <Col span={24}>
                        <Typography.Title level={5} style={{ marginBottom: 20, marginTop: 20 }}>
                          {t('dashboards:temperature')}
                        </Typography.Title>
                        <Area
                          data={temperatureData}
                          xField="date"
                          yField="value"
                          xAxis={{ tickCount: 5 }}
                          areaStyle={{ fillOpacity: 0.5, fill: '#ffd8bf' }}
                          line={{ color: '#ff9c6e' }}
                          annotations={getChartAnnotations(AlarmRuleKey.TEMPERATURE)}
                        />
                      </Col>
                    )}

                    {chart === 'dashboards:battery' && (
                      <Col span={24}>
                        <Typography.Title level={5} style={{ marginBottom: 20, marginTop: 20 }}>
                          {t('dashboards:battery')}
                        </Typography.Title>
                        <Area
                          data={batteryData}
                          xField="date"
                          yField="value"
                          xAxis={{ tickCount: 5 }}
                          areaStyle={{ fillOpacity: 0.5, fill: '#ffd6e7' }}
                          line={{ color: '#ff85c0' }}
                          annotations={getChartAnnotations(AlarmRuleKey.BATTERY)}
                        />
                      </Col>
                    )}

                    {chart === 'dashboards:humidity' && (
                      <Col span={24}>
                        <Typography.Title level={5} style={{ marginBottom: 20, marginTop: 20 }}>
                          {t('dashboards:humidity')}
                        </Typography.Title>
                        <Area
                          data={humidityData}
                          xField="date"
                          yField="value"
                          xAxis={{ tickCount: 5 }}
                          areaStyle={{ fillOpacity: 0.5, fill: '#d9f7be' }}
                          line={{ color: '#95de64' }}
                          annotations={getChartAnnotations(AlarmRuleKey.HUMIDITY)}
                        />
                      </Col>
                    )}

                    {chart === 'dashboards:co2' && (
                      <Col span={24}>
                        <Typography.Title level={5} style={{ marginBottom: 20, marginTop: 20 }}>
                          {t('dashboards:co2')}
                        </Typography.Title>
                        <Area
                          data={co2Data}
                          xField="date"
                          yField="value"
                          xAxis={{ tickCount: 5 }}
                          areaStyle={{ fillOpacity: 0.5, fill: '#bae7ff' }}
                          line={{ color: '#69c0ff' }}
                          annotations={getChartAnnotations(AlarmRuleKey.CO2)}
                        />
                      </Col>
                    )}
                  </Fragment>
                ))}
            </Row>
          </div>
        )}
      </Spin>
    </>
  )
}
export default CustomDashboard
