export const SensorType = Object.freeze({
  CO2: 'Co2',
  WATER: 'Water'
})

export const SensorStatus = Object.freeze({
  ACTIVE: 'Active',
  DISABLED: 'Disabled'
})

export const SensorModel = Object.freeze({
  DRAGINO_LSN50V2_D20: 'DRAGINO_LSN50V2_D20',
  DRAGINO_LSN50V2_S31: 'DRAGINO_LSN50V2_S31',
  DRAGINO_LHT65: 'DRAGINO_LHT65',
  BROWAN_TBHH100: 'BROWAN_TBHH100'
})

export const AlarmRuleKey = Object.freeze({
  ALARM_DELAY: 'AlarmDelay',
  TEMPERATURE: 'Temperature',
  CO2: 'Co2',
  BATTERY: 'Battery',
  HUMIDITY: 'Humidity',
  COLD_WATER_TEMPERATURE: 'ColdWaterTemperature',
  WARM_WATER_TEMPERATURE: 'WarmWaterTemperature',
  EMAIL_NOTIFICATION: 'EmailNotification'
})

export const Role = Object.freeze({
  SUPER_ADMIN: 'Super Admin',
  ADMIN: 'Admin',
  USER: 'User'
})
