import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Col, Form, Input, Row, Select, Button, Space, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { RemoteSelect } from '../shared/components'
import AlarmRulesDetails from './AlarmRulesDetails'
import { Role, SensorModel, SensorStatus, SensorType } from '../shared/helpers'
import { authenticationService } from '../shared/services'

const isSuperAdmin = authenticationService.hasRoles([Role.SUPER_ADMIN])

const SensorDetails = (props) => {
  const [form] = Form.useForm()
  const [sensor, setSensor] = useState({})
  const [customers, setCustomers] = useState([])
  const [customer, setCustomer] = useState(null)
  const [location, setLocation] = useState(null)
  const [site, setSite] = useState(null)
  const [group, setGroup] = useState(null)
  const [room, setRoom] = useState(null)
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState(null)
  const [rules, setRules] = useState([])
  const { t } = useTranslation(['global', 'sensors'])

  const onReset = () => {
    form.resetFields()
    setSensor({})
    setRules([])
    if (props.onCancel) props.onCancel()
  }

  const onSave = (values) => {
    setLoading(true)

    const data = Object.assign(sensor, values)
    data.customerId = customer.id
    const request = !data.id || data.id === 0 ? axios.post('/api/sensors', data) : axios.put(`/api/sensors/${data.id}`, data)

    request
      .then(() => {
        props.onSuccessfullySaved()
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const fetchRoomRules = (roomId) => {
    setLoading(true)
    axios
      .get(`/api/rooms/${roomId}/alarmRules?sensorType=${type}`)
      .then((res) => {
        setRules(res.data)
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false)
      })
  }

  const fetchSensorRules = (sensorId) => {
    setLoading(true)
    axios
      .get(`/api/sensors/${sensorId}/alarmRules`)
      .then((res) => {
        setRules(res.data)
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false)
      })
  }

  const onSelectFilter = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  const onCustomerChange = (id) => {
    const index = customers.findIndex((x) => x.id === +id)
    if (index === -1) return
    console.log('cust', customers[index])
    setCustomer({ ...customers[index] })
  }

  const onLocationChange = (id) => {
    const index = customer.locations.findIndex((x) => x.id === +id)
    if (index === -1) return
    setLocation({ ...customer.locations[index] })
    form.setFieldsValue({
      ...form.getFieldsValue(),
      siteId: null,
      groupId: null,
      roomId: null,
      componentId: null
    })
  }

  const onSiteChange = (id) => {
    const index = location.sites.findIndex((x) => x.id === +id)
    if (index === -1) return
    setSite({ ...location.sites[index] })
    form.setFieldsValue({
      ...form.getFieldsValue(),
      groupId: null,
      roomId: null,
      componentId: null
    })
  }

  const onGroupChange = (id) => {
    const index = site.groups.findIndex((x) => x.id === +id)
    if (index === -1) return
    setGroup({ ...site.groups[index] })
    form.setFieldsValue({
      ...form.getFieldsValue(),
      roomId: null,
      componentId: null
    })
  }

  const onRoomChange = (id) => {
    const index = group.rooms.findIndex((x) => x.id === +id)
    if (index === -1) return
    setRoom({ ...group.rooms[index] })
    form.setFieldsValue({
      ...form.getFieldsValue(),
      componentId: null
    })

    fetchRoomRules(group.rooms[index].id)
  }

  const setDefaultRoom = (g) => {
    const roomIndex = g.rooms.findIndex((x) => x.id === sensor.roomId)
    if (roomIndex !== -1) {
      const r = g.rooms[roomIndex]
      setRoom({ ...r })
    }
  }

  const setDefaultGroup = (s) => {
    const groupIndex = s.groups.findIndex((x) => x.id === sensor.groupId)
    if (groupIndex !== -1) {
      const g = s.groups[groupIndex]
      setGroup({ ...g })
      setDefaultRoom(g)
    }
  }

  const setDefaultSite = (loc) => {
    const siteIndex = loc.sites.findIndex((x) => x.id === sensor.siteId)
    if (siteIndex !== -1) {
      const s = loc.sites[siteIndex]
      setSite({ ...s })
      setDefaultGroup(s)
    }
  }

  const setDefaultLocation = (cus) => {
    console.log('🚀 ~ file: SensorDetails.js ~ line 163 ~ setDefaultLocation ~ cus', cus)
    if (!cus || !cus.locations) return

    const locationIndex = cus.locations.findIndex((x) => x.id === sensor.locationId)
    console.log('🚀 ~ file: SensorDetails.js ~ line 166 ~ setDefaultLocation ~ locationIndex', locationIndex, cus)
    if (locationIndex !== -1) {
      const loc = cus.locations[locationIndex]
      setLocation({ ...loc })
      setDefaultSite(loc)
    }
  }

  useEffect(() => {
    if (customer?.id !== sensor.customerId) {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        customerId: customer?.id,
        locationId: null,
        siteId: null,
        groupId: null,
        roomId: null,
        componentId: null
      })
    } else {
      setDefaultLocation(customer)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  useEffect(() => {
    if (sensor.customerId) {
      onCustomerChange(sensor.customerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers])

  useEffect(() => {
    axios
      .get('/api/customers')
      .then((res) => {
        setCustomers([...res.data.items])
      })
      .catch((error) => console.error(error))
  }, [])

  useEffect(() => {
    const newSensor = { ...props.sensor }

    if (!isSuperAdmin) {
      newSensor.customerId = authenticationService.currentUserValue?.customerId
    }

    setSensor(newSensor)
    setRules(newSensor.alarmRules || [])
    setType(newSensor.type || null)
    form.setFieldsValue(newSensor)

    if (newSensor.id) {
      fetchSensorRules(newSensor.id)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.sensor])

  return (
    <div>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" initialValues={sensor} onFinish={onSave}>
          <Row gutter={24}>
            <Col span={7}>
              <Form.Item label={t('sensors:name')} name="name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              {/* <Form.Item label={t('sensors:type')} name="type" rules={[{ required: true }]}>
                <Select onChange={(value) => setType(value)}>
                  <Select.Option value={SensorType.CO2}>{t('sensors:type_co2')}</Select.Option>
                  <Select.Option value={SensorType.WATER}>{t('sensors:type_water')}</Select.Option>
                </Select>
              </Form.Item> */}
              <Form.Item label={t('sensors:model')} name="model" rules={[{ required: true }]}>
                <Select>
                  <Select.Option value={SensorModel.DRAGINO_LSN50V2_S31}>Dragino LSN50v2-S31</Select.Option>
                  <Select.Option value={SensorModel.DRAGINO_LSN50V2_D20}>Dragino LSN50v2-D20</Select.Option>
                  <Select.Option value={SensorModel.DRAGINO_LHT65}>Dragino LHT65</Select.Option>
                  <Select.Option value={SensorModel.BROWAN_TBHH100}>Browan TBHH100</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label={t('sensors:device_eui')} name="eui" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item label={t('sensors:status')} name="status" rules={[{ required: true }]}>
                <Select>
                  <Select.Option value={SensorStatus.ACTIVE}>{t('sensors:status_active')}</Select.Option>
                  <Select.Option value={SensorStatus.DISABLED}>{t('sensors:status_disabled')}</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label={t('sensors:notes')} name="notes">
                <Input.TextArea showCount rows={4} maxLength="500" />
              </Form.Item>
              <Form.Item>
                <Space align="end">
                  <Button type="primary" htmlType="submit">
                    {t('save')}
                  </Button>
                  <Button htmlType="button" onClick={onReset}>
                    {t('cancel')}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label={t('sensors:customer')} name="customerId" rules={[{ required: true }]}>
                {/* <RemoteSelect
                  endpoint="customers"
                  fieldValue={sensor.customerId}
                  searchProperty="name"
                  disabled={!isSuperAdmin}
                  onChange={(value) => setCustomer(value)}
                /> */}
                <Select showSearch filterOption={onSelectFilter} onChange={onCustomerChange}>
                  {customers &&
                    customers.map((c) => (
                      <Select.Option key={c.id} value={c.id}>
                        {c.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label={t('sensors:location')} name="locationId">
                <Select showSearch filterOption={onSelectFilter} onChange={onLocationChange}>
                  {customer &&
                    customer.locations.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label={t('sensors:site')} name="siteId">
                <Select showSearch filterOption={onSelectFilter} onChange={onSiteChange}>
                  {location &&
                    location.sites.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label={t('sensors:group')} name="groupId">
                <Select showSearch filterOption={onSelectFilter} onChange={onGroupChange}>
                  {site &&
                    site.groups.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label={t('sensors:room')} name="roomId">
                <Select showSearch filterOption={onSelectFilter} onChange={onRoomChange}>
                  {group &&
                    group.rooms.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label={t('sensors:component')} name="componentId">
                <Select showSearch filterOption={onSelectFilter}>
                  {room &&
                    room.components.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <AlarmRulesDetails
                rules={rules}
                type={type}
                loading={loading}
                showSwitch
                endpoint={sensor.id ? `sensors/${sensor.id}` : null}
                onChange={(r) => setSensor({ ...sensor, alarmRules: r })}
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  )
}
export default SensorDetails
