import React, { useEffect, useState } from 'react'
import { Button, Select, Switch, Table, Popconfirm, InputNumber, Spin, Popover, Tooltip, Input, Tag } from 'antd'
import { PlusOutlined, DeleteOutlined, LinkOutlined, InfoOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { AlarmRuleKey, SensorType } from '../shared/helpers'
import axios from 'axios'

const AlarmRulesDetails = (props) => {
  const [enabled, setEnabled] = useState(true)
  const [rules, setRules] = useState([])
  const [type, setType] = useState('Water')
  const [loading, setLoading] = useState(false)
  const [emails, setEmails] = useState([])
  const [emailValue, setEmailValue] = useState('')
  const [emailError, setEmailError] = useState(null)
  const { t } = useTranslation(['global', 'alarms'])

  const getUniqueKey = () => {
    return '_' + Math.random().toString(36).substr(2, 9)
  }

  const onAdd = () => {
    const newRules = [...rules]
    newRules.push({ rowKey: getUniqueKey() })
    setRules(newRules)
  }

  const handleOnChange = (index, record) => {
    const newRules = [...rules]
    newRules[index] = record
    setRules(newRules)
  }

  const handleEnterPressed = (index, record) => {
    if(isValid(emailValue)){    
      const newEmails = [...emails]
      newEmails.push(emailValue)
      setEmails(newEmails)

      const newRules = [...rules]
      newRules[index] = {...record, textValue: newEmails.join(",")}
      setRules(newRules)
      setEmailValue('')
    }
  }

  const handleDeleteTag = (event, index, item, record) => {
    const newEmails = [...emails].filter(i => i !== item)
    const newRules = [...rules]
    newRules[index] = {...record, textValue: newEmails.join(",")}
    setRules(newRules)
    event.preventDefault()
    setEmails(newEmails)
  };

  const onDelete = (record) => {
    const newRules = [...rules].filter((x) => {
      if (record.id) {
        return x.id !== record.id
      }

      return x.rowKey !== record.rowKey
    })
    setRules(newRules)
  }

  const onUnlink = (index) => {
    const newRules = [...rules]
    delete newRules[index].id
    newRules[index].rowKey = getUniqueKey()
    newRules[index].isInherited = false
    setRules(newRules)
  }

  const isSelected = (record, key) => {
    return record.key !== key && rules.some((x) => x.key === key)
  }

  const inheritedPopover = (record) => (
    <div>
      {t(`alarms:${record.sourceType.toLowerCase()}`)}&nbsp;({record.sourceName})
    </div>
  )

  const isValid = (email) => {
    let error = null;

    if (isInList(email)) {
      error = t('alarms:email_error_already_added');
    }

    if (!isEmail(email)) {
      error = t('alarms:email_error_not_valid');
    }

    if (error) {
      setEmailError(error)
      return false;
    }

    setEmailError(error)
    return true;
  }

  const isInList = (email) => {
    return emails.includes(email);
  }

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  const columns = [
    {
      title: t('alarms:value'),
      dataIndex: 'key',
      key: 'value',
      width: '50%',
      render: (_, record, index) => (
        <Select
          style={{ width: '100%' }}
          size="small"
          defaultActiveFirstOption
          disabled={!enabled || record.isInherited}
          defaultValue={record.key}
          onChange={(value) => handleOnChange(index, { ...record, key: value })}
        >
          <Select.Option disabled={isSelected(record, AlarmRuleKey.ALARM_DELAY)} value={AlarmRuleKey.ALARM_DELAY}>
            {t('alarms:key_alarm_delay')}
          </Select.Option>
          <Select.Option disabled={isSelected(record, AlarmRuleKey.EMAIL_NOTIFICATION)} value={AlarmRuleKey.EMAIL_NOTIFICATION}>
            {t('alarms:key_email_notification')}
          </Select.Option>
          {(!type || type === SensorType.CO2) && (
            <>
              <Select.Option disabled={isSelected(record, AlarmRuleKey.TEMPERATURE)} value={AlarmRuleKey.TEMPERATURE}>
                {t('alarms:key_temperature')}
              </Select.Option>
              <Select.Option disabled={isSelected(record, AlarmRuleKey.CO2)} value={AlarmRuleKey.CO2}>
                {t('alarms:key_co2')}
              </Select.Option>
              <Select.Option disabled={isSelected(record, AlarmRuleKey.BATTERY)} value={AlarmRuleKey.BATTERY}>
                {t('alarms:key_battery')}
              </Select.Option>
              <Select.Option disabled={isSelected(record, AlarmRuleKey.HUMIDITY)} value={AlarmRuleKey.HUMIDITY}>
                {t('alarms:key_humidity')}
              </Select.Option>
            </>
          )}

          {(!type || type === SensorType.WATER) && (
            <>
              <Select.Option
                disabled={isSelected(record, AlarmRuleKey.COLD_WATER_TEMPERATURE)}
                value={AlarmRuleKey.COLD_WATER_TEMPERATURE}
              >
                {t('alarms:key_cold_water_temperature')}
              </Select.Option>
              <Select.Option
                disabled={isSelected(record, AlarmRuleKey.WARM_WATER_TEMPERATURE)}
                value={AlarmRuleKey.WARM_WATER_TEMPERATURE}
              >
                {t('alarms:key_warm_water_temperature')}
              </Select.Option>
            </>
          )}
        </Select>
      )
    },
    {
      dataIndex: 'textValue',
      key: 'text',
      width: 0,
      render: (_, record, index) => {
        if (record.key == AlarmRuleKey.EMAIL_NOTIFICATION) {
          return { 
            children: (
              <div>
              {emails.map(item => (
                <>
                  <Tag closable={!record.isInherited}
                    onClose={(e) => handleDeleteTag(e, index, item, record)}
                  >
                    {item}
                  </Tag>
                </>
              ))}
              <Popover placement='bottomRight' visible={emailError != null ? true : false} content={emailError}>
                <Input
                  size="small"
                  type="email"
                  value={emailValue}
                  style={(!enabled || record.isInherited) ? {display: 'none'} : { marginTop: '8px' }}
                  placeholder={t('alarms:email_notification_placeholder')}
                  onPressEnter={(e) => handleEnterPressed(index, record)}
                  onChange={(e) => setEmailValue(e.target.value)}
                  onKeyDown={(e)=> e.key === 'Enter' ? e.preventDefault(): ''}                
                />
              </Popover>
              </div>
            ),
            props: {
              colSpan: 3,
              className: 'email-column'
            }
          }
        }
      return {
        props: {
          colSpan: 1,
        }
      }
      }
    },
    {
      title: t('alarms:min'),
      dataIndex: 'minValue',
      key: 'min',
      width: '25%',
      render: (_, record, index) => {
        if (record.key == AlarmRuleKey.EMAIL_NOTIFICATION) {
          return {
            props: {
              colSpan: 0
            }
          }
        }
        return ( 
        <InputNumber
          size="small"
          disabled={!enabled || record.isInherited}
          decimalSeparator={t('decimal_separator')}
          defaultValue={record.minValue}
          onChange={(v) => handleOnChange(index, { ...record, minValue: v })}
        />
      )
    }
    },
    {
      title: t('alarms:max'),
      dataIndex: 'maxValue',
      key: 'max',
      width: '25%',
      render: (_, record, index) => {
        if (record.key == AlarmRuleKey.ALARM_DELAY) {
          return (
            <Tooltip title={t('alarms:alarm_delay_description')}>
              <Button shape="circle" type="ghost" icon={<InfoOutlined />} size="small" />
            </Tooltip>
          )
        }
        if (record.key == AlarmRuleKey.EMAIL_NOTIFICATION) {
          return {
            props: {
              colSpan: 0
            }
          }
        }

        return (
          <InputNumber
            size="small"
            disabled={!enabled || record.isInherited}
            decimalSeparator={t('decimal_separator')}
            defaultValue={record.maxValue}
            onChange={(v) => handleOnChange(index, { ...record, maxValue: v })}
          />
        )
      }
    },
    {
      title: !!props.showSwitch && (
        <div style={{ textAlign: 'right' }}>
          <Switch size="small" defaultChecked onChange={(checked, e) => setEnabled(checked)} />
        </div>
      ),
      key: 'actions',
      width: 50,
      render: (_, record, index) => (
        <div>
          {!record.isInherited && (
            <Popconfirm
              title={t('delete_record_confirm')}
              okText={t('yes')}
              cancelText={t('no')}
              onConfirm={() => onDelete(record)}
              disabled={!enabled}
            >
              <Button type="text" danger size="small" shape="circle" icon={<DeleteOutlined />} disabled={!enabled} />
            </Popconfirm>
          )}
          {record.isInherited && (
            <Popconfirm
              title={t('alarms:unlink_record_confirm')}
              okText={t('yes')}
              cancelText={t('no')}
              onConfirm={() => onUnlink(index)}
              disabled={!enabled}
            >
              <Popover title={t('alarms:inherited_from')} content={inheritedPopover(record)}>
                <Button type="text" size="small" shape="circle" icon={<LinkOutlined />} disabled={!enabled} />
              </Popover>
            </Popconfirm>
          )}
        </div>
      )
    }
  ]

  useEffect(() => {
    if (typeof props.loading !== 'undefined') setLoading(props.loading)
  }, [props.loading])

  useEffect(() => {
    setRules([...(props.rules || [])])
  }, [props.rules])

  useEffect(() => {
    setType(props.type)
  }, [props.type])

  useEffect(() => {
    if (props.onChange) props.onChange(rules)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rules])

  useEffect(() => {
    if (props.endpoint) {
      setLoading(true)
      axios
        .get(`/api/${props.endpoint}/alarmRules`)
        .then((res) => {
          setRules([...res.data])
          
          let emailAdresses = res.data.find(x => x.key === AlarmRuleKey.EMAIL_NOTIFICATION)
          if(emailAdresses) {
            if(emailAdresses.textValue && emailAdresses.textValue.length > 0){
              setEmails(emailAdresses.textValue.split(','))
            }
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setLoading(false)
        })
    }
  }, [props.endpoint])

  return (
    <div>
      <Spin spinning={loading}>
        <Table
          title={() => t('alarms:alarms_settings')}
          size="small"
          rowKey={(record) => record.id || record.rowKey}
          dataSource={rules}
          columns={columns}
          pagination={false}
          footer={() => (
            <Button type="link" size="small" icon={<PlusOutlined />} onClick={() => onAdd()} disabled={!enabled}>
              {t('alarms:add_alarm_rule')}
            </Button>
          )}
        />
      </Spin>
    </div>
  )
}
export default AlarmRulesDetails
